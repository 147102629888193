@import url('https://fonts.googleapis.com/css2?family=Moon+Dance&family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Newsreader:ital,opsz,wght@0,6..72,200..800;1,6..72,200..800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Serif+Bengali:wght@100..900&display=swap');

*, ::after, ::before{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Noto Serif Bengali", serif;
  /* font-family: sohne-var, "Helvetica Neue", Arial, sans-serif; */
  color: #333;
  /* scroll-behavior: smooth; */
}

input, textarea, select { font-family: 'Poppins', sans-serif; }

body{
  margin: 0;
  padding: 0;
  background-color: #fefdff;
  overflow-x: hidden; /* Hide horizontal scrollbar */
  font-family: 'Poppins', sans-serif;
}

.full-btn{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: lightpink;
  border: none;
  border-radius: 100px;
  text-align: center;
  cursor: pointer;
}

.default-btn{
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: lightpink;
  border: none;
  border-radius: 100px;
  text-align: center;
  cursor: pointer;
}

a{
  color: cornflowerblue;
  font-weight: 300;
}

input{
  border: 1px solid black;
  outline: none;
}

::-webkit-scrollbar {
  width: 8px;
}
 
::-webkit-scrollbar-track {
  background-color: #f0e9e9;
  border-left: 1px solid #e9e5e5;
}
 
::-webkit-scrollbar-thumb {
  background-color: #242222;
}
